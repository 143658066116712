import { onMounted, onUnmounted, Ref, ref, watch } from 'vue'

export function useElementScrollSize(targetElement: Ref<HTMLElement | null>) {
  const width = ref<number>(0)
  const height = ref<number>(0)
  const scrollWidth = ref<number>(0)
  const scrollHeight = ref<number>(0)

  const updateSize = () => {
    const el = targetElement.value

    if (el) {
      width.value = el.clientWidth
      height.value = el.clientHeight
      scrollWidth.value = el.scrollWidth
      scrollHeight.value = el.scrollHeight
    }
  }

  let resizeObserver: ResizeObserver | null = null
  let mutationObserver: MutationObserver | null = null

  const setupObservers = () => {
    const el = targetElement.value

    if (el) {
      // Initial size calculation
      updateSize()

      // ResizeObserver to detect changes in element size
      resizeObserver = new ResizeObserver(updateSize)
      resizeObserver.observe(el)

      // MutationObserver to detect changes in content (DOM subtree changes)
      mutationObserver = new MutationObserver(() => {
        updateSize()  // Recalculate scroll sizes when the content changes
      })

      mutationObserver.observe(el, {
        childList: true,   // Watch for child node changes (additions/removals)
        subtree: true,     // Watch the entire subtree
        characterData: true // Listen for text node changes
      })
    }
  }

  const cleanupObservers = () => {
    if (resizeObserver) {
      resizeObserver.disconnect()
      resizeObserver = null
    }

    if (mutationObserver) {
      mutationObserver.disconnect()
      mutationObserver = null
    }
  }

  // Watch for changes in the targetElement
  watch(targetElement, (newEl, oldEl) => {
    if (oldEl) {
      cleanupObservers()
    }
    if (newEl) {
      setupObservers()
    }
  })

  onMounted(() => {
    setupObservers()
  })

  onUnmounted(() => {
    cleanupObservers()
  })

  return {
    width,
    height,
    scrollWidth,
    scrollHeight
  }
}

export default useElementScrollSize
