import { useI18n } from 'vue-i18n'
import { LocationQuery } from 'vue-router'

export default class RouteHelper {
    public static getLink(path: string, query?: LocationQuery): string {
        const i18n = useI18n();

        // Base URL with locale
        let url = `/${i18n.locale.value}/${path}`;

        // If query parameters exist, append them to the URL
        if (query) {
            const queryString = new URLSearchParams(query as Record<string, string>).toString();
            if (queryString) {
                url += `?${queryString}`;
            }
        }

        return url;
    }
}
