<template>
  <div class='g-gantt__container'>
    <div
        ref="ganttChart"
        :class="['g-gantt-chart', { 'with-column': labelColumnTitle }]"
        :style="{ width, background: colors.background, fontFamily: font }"
    >
      <div class='g-gantt-label__column' id='g-gantt-label__column'>
        <div
            v-for='(columnLabel, index) in columnLabels' :key='columnLabel'
            :class="[
                'g-gantt-label__row',
               columnLabels.length === index + 1 ? 'last-row' : 'not-last-row',
             ]"
            :style="{
                '--item-height': `${VueGanttasticConstants.ROW_HEIGHT}px`,
              }">
          {{ columnLabel.label[locale] }}
        </div>
      </div>
      <GGanttTimeaxis v-if="!hideTimeaxis">
        <template #timeunit="{ label, value, date }">
          <!-- expose timeunit slot of g-gantt-timeaxis-->
          <slot name="timeunit" :label="label" :value="value" :date="date"/>
        </template>
      </GGanttTimeaxis>
      <GGanttCurrentTime
          v-if="isStartAndEndBetweenToday"
          :loading='$props.loading'
          :scrollable-height="chartSize.scrollHeight.value + 'px'">
        <template #current-time-label>
          <slot name="current-time-label"/>
        </template>
      </GGanttCurrentTime>
      <div class="g-gantt-rows-container">
        <slot/>
        <GGanttGrid
            v-if="grid"
            :highlighted-units="highlightedUnits"
            :rows='columnLabels.length'
            :column-labels-row='columnLabels'
            @cellClicked="(value: GGanttGridCellClickEvent) => $emit('cell-clicked', value)">
          <template #new-event-template>
            <slot name='new-event-template'/>
          </template>
        </GGanttGrid>
      </div>
    </div>
    <GGanttBarTooltip :model-value="false || isDragging" :bar="tooltipBar">
      <template #default>
        <slot name="bar-tooltip" :bar="tooltipBar"/>
      </template>
    </GGanttBarTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, onMounted, provide, ref, toRefs, useSlots, withDefaults } from 'vue'
import { GanttBarObject, GGanttChartProps, GGanttGridCellClickEvent } from '@/plugins/vue-ganttastic/types'
import colorSchemes, { ColorSchemeKey } from '@/plugins/vue-ganttastic/color-schemes'
import { CHART_ROWS_KEY, ChartRow, CONFIG_KEY, EMIT_BAR_EVENT_KEY } from '@/plugins/vue-ganttastic/provider/symbols'
import GGanttTimeaxis from '@/plugins/vue-ganttastic/components/GGanttTimeaxis.vue'
import GGanttGrid from '@/plugins/vue-ganttastic/components/GGanttGrid.vue'
import GGanttCurrentTime from '@/plugins/vue-ganttastic/components/GGanttCurrentTime.vue'
import GGanttBarTooltip from '@/plugins/vue-ganttastic/components/GGanttBarTooltip.vue'
import useElementScrollSize from '@/plugins/vue-ganttastic/composables/useElementScrollSize'
import dayjs from 'dayjs'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'
import { useI18n } from 'vue-i18n'

const props = withDefaults(defineProps<GGanttChartProps>(), {
  currentTimeLabel: '',
  loading: false,
  dateFormat: VueGanttasticConstants.DEFAULT_DATE_FORMAT,
  precision: 'hour',
  width: '100%',
  hideTimeaxis: false,
  colorScheme: 'default',
  grid: false,
  pushOnOverlap: false,
  noOverlap: false,
  rowHeight: VueGanttasticConstants.ROW_HEIGHT,
  highlightedUnits: () => [],
  font: 'inherit',
  labelColumnTitle: '',
  labelColumnWidth: '150px'
})

const emit = defineEmits<{
  (e: 'cell-clicked', value: any): void
  (e: 'click-bar', value: { bar: GanttBarObject; e: MouseEvent; datetime?: string | Date }): void
  (
      e: 'mousedown-bar',
      value: { bar: GanttBarObject; e: MouseEvent; datetime?: string | Date }
  ): void
  (e: 'mouseup-bar', value: { bar: GanttBarObject; e: MouseEvent; datetime?: string | Date }): void
  (e: 'dblclick-bar', value: { bar: GanttBarObject; e: MouseEvent; datetime?: string | Date }): void
  (e: 'mouseenter-bar', value: { bar: GanttBarObject; e: MouseEvent }): void
  (e: 'mouseleave-bar', value: { bar: GanttBarObject; e: MouseEvent }): void
  (e: 'dragstart-bar', value: { bar: GanttBarObject; e: MouseEvent }): void
  (e: 'drag-bar', value: { bar: GanttBarObject; e: MouseEvent }): void
  (
      e: 'dragend-bar',
      value: {
        bar: GanttBarObject
        e: MouseEvent
        movedBars?: Map<GanttBarObject, { oldStart: string; oldEnd: string }>
      }
  ): void
  (
      e: 'contextmenu-bar',
      value: { bar: GanttBarObject; e: MouseEvent; datetime?: string | Date }
  ): void
}>()

const { width, font, colorScheme } = toRefs(props)

const { locale } = useI18n()
const slots = useSlots()
const colors = computed(() =>
    typeof colorScheme.value !== 'string'
        ? colorScheme.value
        : colorSchemes[colorScheme.value as ColorSchemeKey] || colorSchemes.default
)
const getChartRows = () => {
  const defaultSlot = slots.default?.()
  const allBars: ChartRow[] = []

  if (!defaultSlot) {
    return allBars
  }
  defaultSlot.forEach((child) => {
    if (child.props?.bars) {
      const { label, bars } = child.props
      allBars.push({ label, bars })
      // if using v-for to generate rows, rows will be children of a single "fragment" v-node:
    } else if (Array.isArray(child.children)) {
      child.children.forEach((grandchild) => {
        const granchildNode = grandchild as {
          props?: ChartRow
        }
        if (granchildNode?.props?.bars) {
          const { label, bars } = granchildNode.props
          allBars.push({ label, bars })
        }
      })
    }
  })
  return allBars
}

const showTooltip = ref(false)
const isDragging = ref(false)
const tooltipBar = ref<GanttBarObject | undefined>(undefined)
let tooltipTimeoutId: ReturnType<typeof setTimeout>
const initTooltip = (bar: GanttBarObject) => {
  if (tooltipTimeoutId) {
    clearTimeout(tooltipTimeoutId)
  }
  tooltipTimeoutId = setTimeout(() => {
    showTooltip.value = true
  }, 800)
  tooltipBar.value = bar
}

const clearTooltip = () => {
  clearTimeout(tooltipTimeoutId)
  showTooltip.value = false
}

const emitBarEvent = (
    e: MouseEvent,
    bar: GanttBarObject,
    datetime?: string | Date,
    movedBars?: Map<GanttBarObject, { oldStart: string; oldEnd: string }>
) => {
  switch (e.type) {
    case 'click':
      emit('click-bar', { bar, e, datetime })
      break
    case 'mousedown':
      emit('mousedown-bar', { bar, e, datetime })
      break
    case 'mouseup':
      emit('mouseup-bar', { bar, e, datetime })
      break
    case 'dblclick':
      emit('dblclick-bar', { bar, e, datetime })
      break
    case 'mouseenter':
      initTooltip(bar)
      emit('mouseenter-bar', { bar, e })
      break
    case 'mouseleave':
      clearTooltip()
      emit('mouseleave-bar', { bar, e })
      break
    case 'dragstart':
      isDragging.value = true
      emit('dragstart-bar', { bar, e })
      break
    case 'drag':
      emit('drag-bar', { bar, e })
      break
    case 'dragend':
      isDragging.value = false
      emit('dragend-bar', { bar, e, movedBars })
      break
    case 'contextmenu':
      emit('contextmenu-bar', { bar, e, datetime })
      break
  }
}

const isStartAndEndBetweenToday = computed(() => {
  console.log('isStartAndEndBetweenToday', {
    now: dayjs(),
    start: props.chartStart,
    end: props.chartEnd,
    isBetween: dayjs().isBetween(dayjs(props.chartStart), dayjs(props.chartEnd))
  })

  return dayjs().isBetween(dayjs(props.chartStart), dayjs(props.chartEnd))
})

onMounted(() => {
  setTimeout(() => {
    const currentTimeElement: HTMLElement | null = document.getElementById('g-grid-current-time')

    if (!currentTimeElement) {
      return
    }

    currentTimeElement.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  }, 250)
})

const ganttChart = ref<HTMLElement | null>(null)
const chartSize = useElementScrollSize(ganttChart)

provide(CHART_ROWS_KEY, getChartRows)
provide(CONFIG_KEY, {
  ...toRefs(props),
  colors,
  chartSize
})
provide(EMIT_BAR_EVENT_KEY, emitBarEvent)
</script>

<style lang='scss'>
div.g-gantt__container {
  display: grid;
  grid-template-areas: 'g-gantt-chart';
  grid-template-columns: auto;
  height: 100%;
  width: 100%;
  overflow: auto;

  .g-gantt-chart {
    grid-area: g-gantt-chart;
    position: relative;
    flex-direction: column;
    -webkit-touch-callout: none;
    user-select: none;
    font-variant-numeric: tabular-nums;
    border-radius: 5px;
    overflow: auto;
    display: grid;
    grid-template-areas: 'g-gantt-timeaxis__container g-gantt-timeaxis__container' 'g-gantt-label__column g-gantt-rows__container';
    grid-template-rows: max-content auto;
    grid-template-columns: 150px auto;

    .g-gantt-label__column {
      grid-area: g-gantt-label__column;
      padding-top: 1px;
      position: sticky;
      top: 0;
      left: 0;
      background-color: white;
      z-index: 4;

      .g-gantt-label__row {
        display: flex;
        align-items: center;
        border-style: solid;
        border-color: #eaeaea;
        border-bottom-width: 1px;
        border-right-width: 1px;
        height: var(--item-height);

        &:last-child {
          height: calc(var(--item-height) - 1px);
        }
      }
    }

    div.g-timeaxis {
      grid-area: g-gantt-timeaxis__container;
      padding-left: 150px;
    }

    div.g-gantt-rows-container {
      grid-area: g-gantt-rows__container;
      overflow: hidden;
    }
  }

  .with-column {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .g-gantt-rows-container {
    position: relative;
  }

  .labels-in-column {
    display: flex;
    flex-direction: row;
  }
}
</style>
