import { computed } from 'vue'
import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import useDayjsHelper from '@/plugins/vue-ganttastic/composables/useDayjsHelper'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'
import { GGanttTimeAxisUnit } from '@/plugins/vue-ganttastic/types'

export default function useTimeaxisUnits() {
  const { precision } = provideConfig()
  const { chartStartDayjs, chartEndDayjs } = useDayjsHelper()

  const upperPrecision = computed(() => {
    switch (precision?.value) {
      case 'minute':
        return 'hour' // Use hour as the upper precision for minute-based view
      case 'hour':
        return 'day'
      case 'day':
        return 'month'
      case 'date':
      case 'week':
        return 'month'
      case 'month':
        return 'year'
      default:
        throw new Error(
          'Precision prop incorrect. Must be one of the following: \'hour\', \'day\', \'date\', \'week\', \'month\''
        )
    }
  })

  const lowerPrecision = computed(() => {
    switch (precision.value) {
      case 'minute':
        return 'minute' // Keep "minute" as lower precision for minute intervals
      case 'date':
        return 'day'
      case 'week':
        return 'isoWeek'
      default:
        return precision.value
    }
  })

  const displayFormats = {
    minute: 'HH:mm',  // Updated for hour:minute format
    hour: 'HH',
    date: 'DD.MMM',
    day: 'DD.MMM',
    week: 'WW',
    month: 'MMMM YYYY',
    year: 'YYYY'
  }

  const timeaxisUnits = computed(() => {
    const upperUnits: Array<GGanttTimeAxisUnit> = []
    const lowerUnits: Array<GGanttTimeAxisUnit> = []
    const upperUnit = upperPrecision.value
    const lowerUnit = lowerPrecision.value
    let currentUpperUnit = chartStartDayjs.value
    let currentLowerUnit = chartStartDayjs.value

    // Generate lower units for every 15 minutes if precision is set to "minute"
    if (precision.value === 'minute') {
      while (currentLowerUnit.isSameOrBefore(chartEndDayjs.value)) {
        const next15MinUnit = currentLowerUnit.add(15, 'minute') // Step by 15 minutes

        lowerUnits.push({
          label: currentLowerUnit.format(displayFormats[precision?.value]),
          value: String(currentLowerUnit),
          date: currentLowerUnit.toDate(),
          width: `${VueGanttasticConstants.COLUMN_WIDTH}px`
        })
        currentLowerUnit = next15MinUnit
      }
    } else {
      // Handle other precisions
      while (currentLowerUnit.isSameOrBefore(chartEndDayjs.value)) {
        const endCurrentLowerUnit = currentLowerUnit.endOf(lowerUnit)

        lowerUnits.push({
          label: currentLowerUnit.format(displayFormats[precision?.value]),
          value: String(currentLowerUnit),
          date: currentLowerUnit.toDate(),
          width: `${VueGanttasticConstants.COLUMN_WIDTH}px`
        })
        currentLowerUnit = endCurrentLowerUnit
          .add(1, lowerUnit === 'isoWeek' ? 'week' : lowerUnit)
          .startOf(lowerUnit)
      }
    }

    // Generate upper units
    while (currentUpperUnit.isSameOrBefore(chartEndDayjs.value)) {
      const endCurrentUpperUnit = currentUpperUnit.endOf(upperUnit)

      upperUnits.push({
        label: currentUpperUnit.format(displayFormats[upperUnit]),
        value: String(currentUpperUnit),
        date: currentUpperUnit.toDate(),
        width: `${VueGanttasticConstants.COLUMN_WIDTH}px`
      })

      currentUpperUnit = endCurrentUpperUnit.add(1, upperUnit).startOf(upperUnit)
    }

    return { upperUnits, lowerUnits }
  })

  return {
    timeaxisUnits
  }
}
