<template>
  <div class="g-timeaxis">
    <div class="g-timeunits-container">
      <div
          v-for="({ label, value, date, width }) in timeaxisUnits.lowerUnits"
          :key="label"
          class="g-timeunit"
          :style="{
          color: colors.text,
          flexDirection: precision === 'hour' ? 'column' : 'row',
          alignItems: precision === 'hour' ? '' : 'center',
          width,
        }"
      >
        <slot name="timeunit" :label="label" :value="value" :date="date">
          <span class='timeunit__label'>{{ label }}</span>
        </slot>
        <div
            v-if="precision === 'hour'"
            class="g-timeaxis-hour-pin"
            :style="{ background: colors.text }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import useTimeaxisUnits from '@/plugins/vue-ganttastic/composables/useTimeaxisUnits'

const { precision, colors } = provideConfig()
const { timeaxisUnits } = useTimeaxisUnits()
</script>

<style>
.g-timeaxis {
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  background: white;
  z-index: 4;
  display: flex;
  flex-direction: column;
}

.g-timeunits-container {
  display: flex;
  width: 100%;
  height: 100%;

  .g-timeunit {
    height: 100%;
    font-size: 65%;
    display: flex;
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: column;
    justify-content: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;

    span.timeunit__label {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-left: 2px;
    }

    .g-timeaxis-hour-pin {
      width: 1px;
      height: 10px;
    }
  }
}
</style>
