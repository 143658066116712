import { computed } from 'vue'

import { GGanttChartConfig } from '@/plugins/vue-ganttastic/types'
import provideConfig from '@/plugins/vue-ganttastic/provider/provideConfig'
import useDayjsHelper from '@/plugins/vue-ganttastic/composables/useDayjsHelper'
import VueGanttasticConstants from '@/plugins/vue-ganttastic/vue-ganttastic-constants'
import { Dayjs } from 'dayjs'

export default function useTimePositionMapping(config: GGanttChartConfig = provideConfig()) {
  const { dateFormat, chartSize } = config
  const { chartStartDayjs, chartEndDayjs, toDayjs, format } = useDayjsHelper(config)
  const oneMinuteSize: number = VueGanttasticConstants.COLUMN_WIDTH / 15

  const totalNumOfMinutes = computed(() => {
    return chartEndDayjs.value.diff(chartStartDayjs.value, 'minutes')
  })

  const mapTimeToPosition = (time: string) => {
    const diffFromStart = toDayjs(time).diff(chartStartDayjs.value, 'minutes', true)

    return Math.ceil(diffFromStart * oneMinuteSize)
  }

  const mapCurrentTimeToPosition = (date: Dayjs) => {
    const diffFromStart = date.diff(chartStartDayjs.value, 'minutes')

    return Math.ceil((diffFromStart * oneMinuteSize) + 150 - (VueGanttasticConstants.CURRENT_TIME_CONTAINER_WIDTH / 2))
  }

  const mapPositionToTime = (xPos: number) => {
    const width = chartSize.scrollWidth.value || 0
    const diffFromStart = (xPos / width) * totalNumOfMinutes.value

    return format(chartStartDayjs.value.add(diffFromStart, 'minutes'), dateFormat.value)
  }

  return {
    mapTimeToPosition,
    mapCurrentTimeToPosition,
    mapPositionToTime
  }
}
